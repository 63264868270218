<template>
<nav class="nav">
  <div class="logoContainer">
    <img :src="require('@/assets/white-logo.png')" alt="">
    <h3 style="margin-left: 30px;">{{navTitle}}</h3>
  </div>
  <div style="display: flex; align-items: center">
      <van-button size="mini" @click="$$i18n.locale = 'cn'" round type="info" v-if="lang != 'cn'">中文</van-button>
      <van-button size="mini" @click="$$i18n.locale = 'en'" round type="info" v-if="lang != 'en'">EN</van-button>
      <!-- <van-button size="mini" @click="$$i18n.locale = 'ko'" round type="info" v-if="lang != 'ko'">한국어</van-button> -->
      <img v-if="haveLogin" class="logoutBtn" @click="logout" style="" :src="require('@/assets/logout.png')" alt="">
  </div>
  
</nav>
</template>

<script>
import Vue from 'vue';
export default {
  props: {
    navTitle: {
      type: String,
      default: "DEEPER NETWORK"
    }
  },
  data() {
    return {
      show: false,
      haveLogin: false,
    }
  },
  computed: {
    lang() {
      return Vue.prototype.$$i18n.locale
    }
  },
  mounted() {
    this.checkLogin()
    
  },
  methods: {
    t(word) {
      return this.$t(`navbar.${word}`)
    },
    checkLogin() {
      const token = window.localStorage.getItem('token');
      if (!token) {
        this.haveLogin = false
        return 
      }
      this.haveLogin = true
    },
    logout() {
      window.localStorage.clear()
      window.location.href= '/login'
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  background-color: #001938;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  height: 60px;
  .logoContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;

  }
}
.navBox {
  display: flex;
  flex-direction: column;
  .navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: #333;
    border-top: 1px solid #ddd;
  }
}
.logoutBtn {
  width: 30px;
  margin-left: 30px;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.9;
  }
}
</style>