<template>
  <div>
    <van-cell-group :title="t('UserInfo')">
      <van-cell :title="t('Username')" :value="userData.username" />
      <van-cell center :title="t('2fa')">
        <template #right-icon>
          <van-switch @change="change2fa" v-model="twoFAValue" size="24" />
        </template>
      </van-cell>
      <van-cell :title="t('ERC20')" :label="userData.walletAddress">
        <!-- <div slot="default">
        <van-button @click="$refs.EditERC20.open(userData.walletAddress)" type="info" plain size="mini" icon="edit"></van-button>
        </div> -->
      </van-cell>
      <van-cell :title="t('sn')" :label="userData.sn"></van-cell>
      <van-cell :title="t('DeeperChainWallet')" >
        <div slot="label" style="word-break: break-all">
          <span v-if="!needCreateWallet">{{deeperChain}}</span>
          <div v-else style="text-align: center">
            <van-button @click="$refs.CreateWallet.create" size="mini" style="width: 50%" plain type="info">{{$t('user.createWallet')}}</van-button>
          </div>
        </div>
      </van-cell>
      <van-cell v-if="stakingDeeperChain && stakingDeeperChain != deeperChain" :title="$t('user.haveStakedDeeperChain')">
        <div slot="label" style="word-break: break-all; color: orangered">
          <span>{{stakingDeeperChain}}</span>
          <div>{{$t('user.deeperChainError')}}</div>
        </div>
      </van-cell>
      <template v-if="deviceSNType == 'IGG' || deviceSNType == 'HW' || deviceSNType == 'HWA'">
        <van-cell :title="$t('user.openMining')" :label="$t('user.openMiningTips')" >
          <div slot="default">
            <van-icon size="30" color="#07c160" v-if="openNow" name="passed" />
            <van-icon size="30" color="#ff976a" v-else name="close" />
          </div>
        </van-cell>
      </template>
      
      <van-cell :title="$t('user.stakedDPR')" :value="stakingAmount"></van-cell>
      <van-cell :title="$t('user.credit')" :value="nowScore"></van-cell>
      
      <!-- <van-cell v-if="!validateEmail(userData.username)" :title="t('email')" @click="$refs.EditEmail.open(userData.email)" :value="userData.email" is-link></van-cell> -->
    </van-cell-group>
    <div style="padding: 30px" >
      <!-- <div style="text-align: center">{{$t('user.waitingStart')}}</div> -->
      <van-button v-if="nowScore < 800" :disabled="getNextDisable()" block type="primary" @click="$emit('next')">{{$t('user.startStake')}}</van-button>
    </div>
    <CreateWallet @success="reload" ref="CreateWallet"></CreateWallet>
    <div style="display: flex; justify-content: space-between; padding:20px;">
      <van-button :disabled="!!(stakingAmount/1 > 1000) || deviceSNType == 'DPR'" @click="deleteUser" size="small">{{$t('user.deleteUser')}}</van-button>
      <van-button :disabled="!!(stakingAmount/1)" @click="openChangeErc20" size="small">{{$t('user.changeErc20')}}</van-button>
    </div>
    <!-- <div style="text-align: center" >
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfBRBy7jyccJwdCJzWliDxWQ63deV0jSFebFxlEA4U8hHyWKg/viewform" target="_blank">Information modification application form</a>
    </div> -->
    <van-popup style="padding: 30px;width: 100%;max-width: 550px;" v-model="editErc20Modal">
      <div >
        <van-form style="width: " @submit="submitErc20">
          <van-field
            v-model="newErc20"
            name="ERC20"
            label="ERC20"
            :placeholder="$t('register.inputWalletAddress')"
            :rules="[{required: true}]"
          />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">{{$t('user.Submit')}}</van-button>
          </div>
        </van-form>
      </div>
      
    </van-popup>
  </div>
</template>

<script>
const {getScore} = require('./score');
import CreateWallet from '@/components/CreateWallet/CreateWallet';
export default {
  components: {
    CreateWallet
  },
  props: {
    comps: null
  },
  data() {
    return {
      editErc20Modal: false,
      newErc20: '',
      twoFAValue: false,
      deeperChain: "",
      deviceSNType: "",
      haveOpenMiningBefore: '',
      openNow: '',
      isInOpenList: '',
      stakingAmount: '',
      stakingDeeperChain: '',
      userData: {
        phone: "",
        walletAddress: "",
      },
      scoreMap: {},
      nowScore: 0,
      needCreateWallet: false,
      stakingCreditData: null
    };
  },
  async mounted() {
    this.$toast.loading({
      forbidClick: true,
      duration: 0,
      overlay: true
    })
    await this.getData()
    await this.getDeeperChain()
    this.getUser2fa()
    await this.checkOpenMiningBefore()
    this.getScoreData()
    await this.getStakingData()
    this.$toast.clear()
    
  },
  beforeDestroy() {
    this.$toast.clear()
  },
  methods: {
    deleteUser() {
      this.$Dialog.confirm({
        message: this.$t('user.deleteUserConfirm')
      }).then(() => {
        this.$fetch({
          headers: {
            Authorization: window.localStorage.getItem("token")
          },
          url: '/user/user',
          method: 'DELETE',
        }).then(res => {
          if (res.success) {
            window.localStorage.clear()
            window.location.href= '/login'
          }
        })
        
      })
    },
    openChangeErc20() {
      this.editErc20Modal = true
    },
    submitErc20() {
      if (!/^(0x)?[0-9a-fA-F]{40}$/.test(this.newErc20)) {
        return this.$ctToast("请输入正确的ERC20钱包地址");
      }
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("token")
        },
        url: '/user/erc20',
        method: 'PUT',
        data: {
          erc20: this.newErc20
        }
      }).then(res => {
        if (res.success) {
          this.$ctToast("修改成功，请重新登录")
          setTimeout(() => {
            window.localStorage.clear()
            window.location.href= '/login'
          }, 3000)
          
        }
      })
    },
    reload() {
      window.location.reload()
    },
    getNextDisable() {
      if (this.deviceSNType == 'DPRB' && this.nowScore == 400) {
        return true
      }
      if (this.stakingDeeperChain && this.stakingDeeperChain != this.deeperChain ) {
        return true
      }
      if (!this.deeperChain) {
        return true
      }
      if (this.nowScore >= 800) {
        return true
      }
      return false
    },
    getDeeperChain() {
      this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("token")
        },
        url: '/user/deeperChain'
      }).then(res => {
        console.log('getDeeperChain')
        if (res.success) {
          if (res.data) {
            this.deeperChain = res.data
          } else {
            if (this.deviceSNType == 'DPR' || this.deviceSNType == 'DPRB') {
              this.$Dialog({
                message: this.$t('user.noWalletAndCreate')
              })
            } else {
              if (this.userData.deeperWallet) {
                this.deeperChain = this.userData.deeperWallet
              } else {
                this.needCreateWallet = true
                this.$Dialog({
                  message: this.$t('user.needCreateWallet')
                })
                
              }
            }
          }
        }
        console.log(res)
      })
    },
    getScoreData() {
      this.scoreMap = getScore(this.deviceSNType, this.haveOpenMiningBefore)
      console.log('getScoreData')
      console.log(this.scoreMap)
    },
    async getStakingData() {
      const staking = await this.comps.Step3.getStaking(this.userData.walletAddress);
      console.log(staking)
      if (!staking) {
        return
      }
      this.stakingAmount = (staking.dprAmount/1e18).toFixed(4)/1;
      this.nowScore = staking.credit;
      this.stakingDeeperChain = staking.deeperChain;
      this.stakingCreditData = staking.creditData
    },
    getUser2fa() {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/2fa'
      }).then(res => {
        if (res.data) {
          this.twoFAValue = res.data.status == 'OPEN' ? true : false
        }
      })
    },
    change2fa(value) {
      this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/2fa',
        method: 'POST',
        data: {
          status: value ? 'OPEN' : 'CLOSE'
        }
      }).then(res => {
        if (res.data) {
          this.twoFAValue = res.data.status == 'OPEN' ? true : false
        }
      })
    },
    t(word) {
      return this.$t(`user.${word}`)
    },
    getData() {
      return this.$fetch({
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
        },
        url: '/user/getAccount'
      }).then(res => {
        this.userData = res.data;
        if (this.userData.sn) {
          this.deviceSNType = this.userData.sn.split('-')[2]
        }
      }).catch(e => {
        if (e === 'notAuthenticated' || e === 'notRegistered') {
          this.$router.replace('/login')
          window.localStorage.clear()
        }
      })
    },
    checkOpenMiningBefore() {
      return this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("token")
        },
        url: '/user/checkOpenMiningBefore'
      }).then(res => {
        if (res.success) {
          this.haveOpenMiningBefore = !!res.data.openBefore
          this.openNow = res.data.checkOpen
        }
      })
    }
  }
};
</script>

<style>
</style>