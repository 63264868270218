
import axios from 'axios';

const fetch = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api-device.deeper.network/' : 'http://localhost:7001',
  timeout: 180000
})


export default fetch

