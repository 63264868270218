module.exports = {
  resCommon: {
    alreadySent: "The verification code has been sent, please try again later.",
    invalidVerifyCode: "The verification code is incorrect or has expired",
    walletTaken: "The wallet address has been registered",
    differentWallet: "The username has been registed",
    alreadyRegistered: "The username and wallet have been registered",
    addressTaken: "The mailing address has already been registered",
    invalidInviteCode: "The invitation code has expired",
    invalidCredential: "wrong user name or password",
    notRegistered: "The username is not registered",
    notAuthenticated: "Login expired, please login again",
    phoneTaken: "The phone number is already in use",
    sendFailed: "Failed to send",
    invalidEmail: "invalid Email",
    haveParentCode: "You have bound the invitation code before",
    invalidImgCode: "Invalid image verification code",
    eth_noLogin: "You are not logged in, please refresh the page",
    eth_noAccount: "The information is wrong",
    eth_invalidAccount: "Incorrect wallet address",
    eth_noNonce: "The information is wrong, please refresh and try again",
    eth_noVote: "The information is wrong",
    eth_haveVote: "You have already voted",
    eth_noDPR: "Your wallet does not have DPR",
    cannotLoginSN:
      "This type of device is temporarily unable to participate in the event, please wait for the announcement.",
    invalidSN: "This SN serial number cannot be registered",
    walletOrSNTaken: "The ERC20 address or SN number has already been used.",
    invalidStakingCode: "The pledge code is wrong or has been bound.",
    haveStake: "Your wallet has been staked and cannot be changed",
  },
  navbar: {
    "User Information": "User Information",
    "Reset Password": "Reset Password",
    "Sign Out": "Sign Out",
    "Sign In": "Sign In",
    "Sign Up": "Sign Up",
  },
  register: {
    VerificationCode: "Verification Code",
    inputCode: "Please input Verification code",
    Send: "Send",
    Password: "Password",
    PasswordAgain: "Confirm Password",
    inputPassword:
      "Password must be alphanumeric and at least 8 characters long.",
    inputPasswordAgain: "Please confirm password",
    "DPR Wallet": "ERC20 wallet",
    inputDPRWallet: "Please input your ERC20 wallet",
    toSignIn: "Sign in",
    forgetPsw: "Forget password?",
    UserInfo: "User Information",
    mobile: "Mobile",
    email: "Email",
    inputEmail: "Please input Email",
    DPRWallet: "ERC20 wallet",
    "Delivery Information": "Delivery Information",
    Country: "Country",
    Province: "State / Province",
    City: "City",
    Address: "Address",
    Zip: "Zip / Postal Code",
    InviteCode: "Referral code",
    walletAddress: "Erc20 address",
    inputWalletAddress: "Don't use exchange wallets!",
    "Deeper Chain Wallet": "Deeper Chain Wallet",
    Edit: "Edit",
    inputCountry: "Please input country",
    inputProvince: "Please input province",
    inputCity: "Please input city",
    inputZip: "Please input zip",
    inputInviteCode: "Please input referral code if you have",
    inputAddress: "Please input address",
    Submit: "Submit",
    registerSuccess: "Register successfully!",
    imgCode: "Image code",
    inputImgCode: "Please enter the image code",
    useBsc: "It is recommended to use BSC, which saves the handling fee.",
    useBscRegisterTips:
      "Using ETH network, there will be a high gas cost, using BSC to save gas. Once the eth network is successfully staked, the network cannot be changed. Use BSC immediately?",
    LoginCode: "Device login page code",
    inputLoginCode: "Please enter the device login page code",
    SN: "SN",
    inputSN: "Please enter the device SN",
  },
  login: {
    VerificationCode: "Verification Code",
    inputCode: "Please input Verification code",
    Send: "Send",
    mobile: "Mobile",
    email: "Email",
    inputEmail: "Please input Email",
    Password: "Password",
    inputPassword: "Please input password",
    "Sign In": "Sign in",
    "Sign Up": "Sign up",
    forgetPsw: "forget password?",
    imgCode: "Image code",
    inputImgCode: "Please enter the image code",
    useBscTips: "please use BSC to stake.",
  },
  changePassword: {
    mobile: "Mobile",
    email: "Email",
    inputEmail: "Please input Email",
    VerificationCode: "Code",
    inputCode: "Please input code",
    Send: "Send",
    Password: "Password",
    PasswordAgain: "Confirm Password",
    inputPassword: "Please input new password",
    inputPasswordAgain: "Please confirm new password",
    Submit: "Submit",
    imgCode: "Image code",
    inputImgCode: "Please enter the image code",
  },
  vuePhoneComponent: {
    Callingcode: "Calling code",
    EnterPhoneNumber: "Enter phone number",
  },
  createWallet: {
    clickInOrder:
      "Please click the buttons below in order according to the mnemonic just now.",
    recordTips:
      "Please write down your mnemonic phrase and keep it offline in a safe place. DO NOT take screenshots or save them on your computer! If you forget your username and password, you will need the 12-word mnemonic phrase. Without both the private key & mnemonic phrase, your wallet will be permanently lost and NO ONE will be able to help you retrieve your wallet, including the Deeper Network Team.",
    Reset: "Reset",
    Confirm: "Confirm",
    confirmBtnText: "I wrote down the mnemonic phrase",
    confirmError: "Incorrect order",
    DownloadKey: "Download private key",
    Back: "Back",
    createTips: "Staking activities require Deeper Chain wallet",
    createNow: "Create wallet",
    notNow: "Not now",
  },
  user: {
    UserInfo: "User Info",
    mobile: "mobile",
    Username: "Username",
    Name: "Name",
    Phone: "Phone",
    ERC20: "ERC20 wallet address",
    DeeperChainWallet: "Deeper Chain wallet",
    "Create Wallet": "Create Wallet",
    Stake: "Stake",
    email: "email",
    "2fa": "2fa",
    sn: "SN",
    step1: "Confirm information",
    step2: "Choose an upgrade plan",
    step3: "Stake",
    step4: "Finish",
    createWallet: "Create Wallet",
    haveStakedDeeperChain: "Deeper Chain address staked",
    deeperChainError:
      "The Deeper Chain address bound to your device is inconsistent with the Deeper Chain address you staked.",
    stakedDPR: "DPR staked",
    credit: " Credit Score From Staking",
    waitingStart: "Please wait for the event announcement",
    noWalletAndCreate:
      "Your device has not created a wallet, please go to the device background to create a wallet.",
    needCreateWallet: "You need to create a DeeperChain wallet first",
    needDPRtoUpdate: "DPR needed",
    apr: "APR",
    dailyReward: "daily",
    previous: "Previous",
    stake: "Stake",
    SN: "SN",
    needRegisterWallet: "Please use your registered wallet to operate",
    DPRBalance: "DPR balance in wallet",
    noBalance: "Insufficient balance",
    stakeSuccess:
      "The stake is successful, please wait patiently for the credits to be synchronized to the device.",
    backToIndex: "Back",
    records: "Records",
    time: "time",
    status: "status",
    detail: "detail",
    confirmStakeWithPending:
      "You have a transaction in progress. Are you sure you want to continue to initiate a staking?",
    startStake: "Choose a Plan",
    openMining: "Mining Active",
    openMiningTips: `If you have a green check mark next to "Mining Active" but cannot see the $DPR tab on your AtomOS dashboard, please update to the latest firmware and restart your device. If you see a red X next to "Mining Active", then you'll need to be placed on the Deeper Chain before you can activate mining. `,
    canStake1000:
      "If you have not activated the mining function, please wait patiently for the review, or directly pledge the upgrade; if you have activated the mining function but you still can’t see the mining page in the backend of your device, please make sure that the backend system of your device has been upgraded to Latest, and restart the device.",
    canStake1000Btn: "Only stake 1000DPR",
    changeErc20: "change ERC20 wallet",
    Submit: "Submit",
    deleteUser: "Clear this account",
    deleteUserConfirm:
      "Are you sure you want to clear this account? If you staked on the eth network, you will lose this amount of staking and you need to stake again. Please decide carefully.",
  },
  common: {
    inform1:
      "The staking is about to start, please be patient and follow our notice at any time. When the staking starts, the ERC20 wallet address and Deeper Chain wallet cannot be modified. Please confirm the relevant information as soon as possible.",
    两次密码输入不一致: "Two password entries are inconsistent",
    请输入正确的DPR钱包地址: "Please enter the correct ERC20 wallet address",
    请输入正确的ERC20钱包地址: "Please enter the correct ERC20 wallet address",
    请输入手机号码: "Please enter the phone number",
    请输入正确的邮箱地址: "Please enter the correct Email",
    请输入验证码: "Please enter the phone verification code",
    请输入密码: "Please enter the password",
    请输入正确的手机号码: "Please enter the correct phone number",
    请先登录: "please log in first",
    "登录超时，请重新登录": "Login timed out, please log in again",
    请完善钱包地址:
      "Please complete the ERC20 address and Deeper Chain address",
    请尽快完善收货地址信息:
      "Please complete the delivery address information as soon as possible",
    请输入图片验证码: "Please enter the image verification code",
    "修改成功，请重新登录":
      "The modification is successful, please log in again",
  },
};
