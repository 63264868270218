module.exports = {
  resCommon: {
    alreadySent: "验证码已发送，请稍后再试。",
    invalidVerifyCode: "验证码不正确或已经过期",
    walletTaken: "该钱包地址已经被注册",
    differentWallet: "该用户已注册",
    alreadyRegistered: "该用户名或钱包已经被注册",
    addressTaken: "该邮寄地址已经被注册",
    invalidInviteCode: "邀请码已失效",
    invalidCredential: "用户名或密码错误",
    notRegistered: "该用户名没有被注册",
    notAuthenticated: "该账户登录失效，请重新登录",
    phoneTaken: "该手机号码已经被人使用",
    sendFailed: "发送失败",
    invalidEmail: "邮箱格式错误",
    haveParentCode: "您之前已绑定邀请码",
    invalidImgCode: "图片验证码错误",
    invalidSN: "此SN序列号不能注册",
    walletOrSNTaken: "ERC20地址或SN号已经被人使用。",
    cannotLoginSN: "此类型矿机暂时无法参加活动，请等待公告。",
    invalidStakingCode: "质押码错误或已被绑定。",
    haveStake: "您的钱包已经质押，无法更改",
  },
  navbar: {
    "User Information": "用户信息",
    "Reset Password": "重置密码",
    "Sign Out": "登出",
    "Sign In": "登录",
    "Sign Up": "注册",
  },

  register: {
    VerificationCode: "验证码",
    inputCode: "请输入验证码",
    Send: "发送验证码",
    Password: "密码",
    inputPassword: "数字+字母不少于8位",
    PasswordAgain: "重复密码",
    inputPasswordAgain: "请确认密码",
    SN: "设备序列号",
    inputSN: "请输入设备序列号",
    LoginCode: "设备登录页面编码",
    inputLoginCode: "请输入设备登录页面编码",
    walletAddress: "ERC20钱包地址",
    inputWalletAddress: "请输入ERC20钱包地址",
    toSignIn: "已有账户，去登录",
    forgetPsw: "忘记密码？",

    UserInfo: "用户信息",
    email: "邮箱",
    inputEmail: "请输入邮箱地址",
    mobile: "手机号码",
    "Delivery Information": "收货信息",
    Country: "国家",
    Province: "省份",
    City: "城市",
    Zip: "邮编",
    InviteCode: "邀请码",
    Address: "详细地址",
    "Deeper Chain Wallet": "Deeper Chain 钱包",
    inputCountry: "请输入国家",
    inputProvince: "请输入省份",
    inputCity: "请输入城市",
    inputZip: "请输入邮编",
    inputAddress: "请输入详细地址精确到门牌号",
    inputInviteCode: "请输入邀请码(如果有)",
    Submit: "提交信息",
    Edit: "修改信息",
    registerSuccess: "注册成功！",
    imgCode: "图片验证码",
    inputImgCode: "请输入图片验证码",
    useBsc: "推荐使用BSC质押，更省手续费。",
    useBscRegisterTips:
      "使用eth网络质押，会有高昂的gas费用，使用bsc质押更省gas费。一旦选择了eth网络质押成功，就无法更改网络。立即使用bsc质押？",
  },
  login: {
    VerificationCode: "验证码",
    inputCode: "请输入验证码",
    Send: "发送验证码",
    Password: "密码",
    mobile: "手机号码",
    email: "邮箱",
    inputEmail: "请输入邮箱地址",
    Password: "密码",
    inputPassword: "请输入密码",
    "Sign In": "登录",
    "Sign Up": "前往注册",
    forgetPsw: "忘记密码？",
    imgCode: "图片验证码",
    inputImgCode: "请输入图片验证码",
    useBscTips: "请使用BSC质押。更省手续费。",
  },
  changePassword: {
    mobile: "手机号码",
    email: "邮箱",
    inputEmail: "请输入邮箱地址",
    inputPassword: "数字+字母不少于8位",
    VerificationCode: "验证码",
    inputCode: "请输入验证码",
    Send: "发送验证码",
    Password: "新密码",
    PasswordAgain: "重复新密码",
    inputPasswordAgain: "请确认新密码",
    Submit: "提交信息",
    imgCode: "图片验证码",
    inputImgCode: "请输入图片验证码",
  },
  vuePhoneComponent: {
    Callingcode: "国家或地区码",
    EnterPhoneNumber: "请输入手机号码",
  },
  createWallet: {
    clickInOrder: "请根据刚才的助记词，按顺序点击下面的按钮。",
    recordTips:
      "请务必用笔记下接下来的助记词，并妥善保管，不要截图也不要复制保存在互联网。如果遗失助记词，钱包里的资产将永久丢失，任何人都无法找回钱包(包括Deeper)。",
    Reset: "重置",
    Confirm: "确认",
    confirmBtnText: "我已记录和妥善保管助记词",
    confirmError: "助记词输入不正确",
    DownloadKey: "下载私钥",
    Back: "返回",
    createTips: "质押活动需要Deeper Chain钱包",
    createNow: "创建钱包",
    notNow: "以后再说",
  },
  user: {
    UserInfo: "用户信息",
    mobile: "手机号码",
    Username: "用户名",
    Name: "姓名",
    Phone: "手机号码",
    ERC20: "ERC20钱包地址",
    DeeperChainWallet: "Deeper Chain 钱包",
    walletTip: "可以使用Polkawallet或者支持波卡的钱包导入助记词或私钥",
    Submit: "提交信息",
    AddressInfo: "收货信息",
    "Create Wallet": "创建钱包",
    Stake: "质押",
    Skip: "以后再说",
    ChangeWallet: "重新创建一个钱包",
    email: "电子邮箱",
    "2fa": "登录需要验证码",
    sn: "设备sn号",
    step1: "确认信息",
    step2: "选择升级方案",
    step3: "开始质押",
    step4: "质押完成",
    createWallet: "创建钱包",
    haveStakedDeeperChain: "已质押的Deeper Chain 地址",
    deeperChainError:
      "您的设备绑定的Deeper Chain 地址跟您质押的Deeper Chain 地址不一致。",
    stakedDPR: "已质押DPR数量",
    credit: "质押获取信用分",
    waitingStart: "请等待活动公告",
    noWalletAndCreate: "您的设备未创建钱包，请到设备后台创建钱包。",
    needCreateWallet: "您需要先创建DeeperChain钱包",
    needDPRtoUpdate: "升级所需DPR",
    apr: "年化收益率",
    dailyReward: "日收益DPR",
    previous: "上一步",
    stake: "质押",
    SN: "设备序列号",
    needRegisterWallet: "请使用您登记的钱包进行操作",
    DPRBalance: "钱包里DPR余额",
    noBalance: "余额不足",
    stakeSuccess: "质押成功，请耐心等待信用分同步到设备中。",
    backToIndex: "返回首页",
    records: "质押记录",
    time: "时间",
    status: "状态",
    detail: "详情",
    confirmStakeWithPending: "您有正在处理的交易，确认要继续发起质押吗？",
    startStake: "开始质押升级",
    openMining: "是否已开通挖矿功能",
    openMiningTips:
      "如果您还未开通挖矿功能，请耐心等待审核，或者直接质押升级；如果您已开通挖矿功能，但是您的设备后台还看不到挖矿页面，请确认您的设备后台系统已经升级到最新，并重启设备。",
    canStake1000:
      "您尚未开通挖矿功能，您也可以只质押1000DPR开通挖矿功能获得0信用分。",
    canStake1000Btn: "只质押1000DPR",
    changeErc20: "修改ERC20钱包地址",
    deleteUser: "清除此账号",
    deleteUserConfirm:
      "您确定要清除此账号吗？如果您在eth网络质押了，将会失去此质押量，需要重新质押。请谨慎决定。",
  },

  common: {
    inform1:
      "质押大赛即将开始，请耐心等待，随时关注我们的通知。质押大赛开始时，将不能 修改 ERC20钱包地址 和 Deeper Chain 钱包，请尽快确认相关信息。",
    两次密码输入不一致: "两次密码输入不一致",
    请输入正确的ERC20钱包地址: "请输入正确的ERC20钱包地址",
    请输入手机号码: "请输入手机号码",
    请输入正确的邮箱地址: "请输入正确的邮箱地址",
    请输入验证码: "请输入验证码",
    请输入密码: "请输入密码",
    请输入正确的手机号码: "请输入正确的手机号码",
    请先登录: "请先登录",
    "登录超时，请重新登录": "登录超时，请重新登录",
    请完善钱包地址: "请完善ERC20 和 Deeper Chain 钱包地址",
    请尽快完善收货地址信息: "请尽快完善收货地址信息",
    请输入图片验证码: "请输入图片验证码",
    请检查设备登录页面的编码是否正确: "请检查设备登录页面的编码是否正确",
    该设备不能参与此次活动: "该设备不能参与此次活动",
    "修改成功，请重新登录": "修改成功，请重新登录",
  },
};
