<template>
  <div class="registerContainer">
    <Navbar navTitle=" "></Navbar>
    <main style="max-width: 600px; margin: 20px auto 0; padding-bottom: 100px;">
      <div class="formBox">
        <div class="stepBox">
          <van-steps :active="stepIndex">
            <van-step>{{$t('user.step1')}}</van-step>
            <van-step>{{$t('user.step2')}}</van-step>
            <van-step>{{$t('user.step3')}}</van-step>
            <van-step>{{$t('user.step4')}}</van-step>
          </van-steps>
        </div>
        <div class="stepContent">
          <Step1 :comps="$refs" ref="Step1" v-show="stepIndex == 0" @next="stepIndex = 1"></Step1>
          <Step2 :comps="$refs" ref="Step2" v-show="stepIndex == 1" @next="stepIndex = 2" @prev="stepIndex = 0"></Step2>
          <Step3 :comps="$refs" ref="Step3" v-show="stepIndex == 2" @next="stepIndex = 3" @prev="stepIndex = 1"></Step3>
          <Step4 :comps="$refs" ref="Step4" v-show="stepIndex == 3" @next="stepIndex = 0" @prev="stepIndex = 2"></Step4>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'
import Step4 from './Step4.vue'
export default {
  components: {
    Footer,
    Step1,
    Step2,
    Step3,
    Step4
  },
  data() {
    return {
      stepIndex: 0,
    }
  },
  watch: {
    stepIndex(newVal) {
      if (this.$refs[`Step${newVal+1}`] && this.$refs[`Step${newVal+1}`].init) {
        this.$refs[`Step${newVal+1}`].init()
      }
    }
  }
  
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>

.registerContainer {
  min-height: 100%;
  background: #001938;
}
.stepBox {
  padding: 20px;
}
.formBox {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 0;
  overflow: hidden;
  box-shadow: 0 0px 5px #333;
}
.stepContent {
  padding-bottom: 30px;
}

</style>
