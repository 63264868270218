import { render, staticRenderFns } from "./RegisterSuccess.vue?vue&type=template&id=902fa904&scoped=true"
import script from "./RegisterSuccess.vue?vue&type=script&lang=js"
export * from "./RegisterSuccess.vue?vue&type=script&lang=js"
import style0 from "./RegisterSuccess.vue?vue&type=style&index=0&id=902fa904&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "902fa904",
  null
  
)

export default component.exports