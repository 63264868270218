<template>

  <div class="successContainer">
    <div style="text-align: center">
      <van-icon size="120" name="passed" />
      <div class="textBox">
        {{$t('user.stakeSuccess')}}
      </div>
      <div style="margin-top: 30px">
        <van-button @click="reload" type="primary">{{$t('user.backToIndex')}}</van-button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {


    }
  },
  mounted() {

  },
  methods: {
    reload() {
      window.location.reload()
    }
  }
}
</script>

<style lang="less" scoped>
.successContainer {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  /deep/ .van-icon-passed {
    color: #07c160
  }
  .textBox {
    margin-top: 30px;
    font-size: 20px;
  }
}
</style>