import { mnemonicGenerate, mnemonicToMiniSecret } from '@polkadot/util-crypto';
import { u8aToHex } from '@polkadot/util';
import { Keyring } from '@polkadot/keyring';

export default function create() {
  const mnemonic = mnemonicGenerate();
  const keyring = new Keyring({
    type: 'sr25519'
  });
  const pair = keyring.addFromUri(mnemonic);
  const secretArr = mnemonicToMiniSecret(mnemonic)
  const secret = u8aToHex(secretArr);
  return {
    publicKey: pair.address,
    privateKey: secret,
    mnemonic,
  }
}