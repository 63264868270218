<template>
<van-popup style="width: 100%; height: 100%" v-model="modalStatus">
  <div class="successContainer">
    <div style="text-align: center">
      <van-icon size="120" name="passed" />
      <div class="textBox">
        {{$t('register.registerSuccess')}} ({{count}})
      </div>
    </div>
  </div>
</van-popup>

</template>

<script>
export default {
  data() {
    return {
      count: 3,
      modalStatus: false
    }
  },
  mounted() {

  },
  methods: {
    open() {
      this.modalStatus = true
      this.countDown()
    },
    async countDown() {
      while (this.count>0) {
        await this.$sleep(1000)
        this.count--
      }
      this.$emit('success')
    }
  }
}
</script>

<style lang="less" scoped>
.successContainer {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  /deep/ .van-icon-passed {
    color: #07c160
  }
  .textBox {
    margin-top: 30px;
    font-size: 20px;
  }
}
</style>