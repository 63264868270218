<template>
  <footer class="footer">
    <div style="display: flex;justify-content: center;align-items: center">
      <a href="https://t.me/DeeperNetwork" target="_blank">
        <img style="width:30px" :src="require('@/assets/telegram.png')" alt=""> 
      </a>
      <span style="color: #eee;font-size: 12px;margin-left: 10px">{{lang[$$i18n.locale].word}}</span>
    </div>
    <div style="margin-top: 10px;">
      <a  target="_blank" href="https://app.uniswap.org/#/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0xf3ae5d769e153ef72b4e3591ac004e89f48107a1&use=V2">
        <van-button size="small">{{lang[$$i18n.locale].buy}}</van-button>
      </a>
    </div>
    
  </footer>
</template>

<script>
export default {
  data() {
    return {
      lang: {
        en: {
          word: 'Join us and don’t miss a message',
          buy: 'Buy DPR on Uniswap'
        },
        cn: {
          word: '加入我们，时刻关注信息',
          buy: '点击购买DPR'
        },
        ko: {
          word: '우리와 함께, 항상 정보에주의를 기울이십시오',
          buy: 'Uniswap에서 DPR 구매'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  text-align: center;
  padding: 30px 0 30px
}
</style>