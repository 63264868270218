<template>
  <div class="main">
    <div style="height: 20px"></div>
    <div class="mainContainer">
      <div style="display: flex; justify-content: flex-end; align-items: center; margin-bottom: 10px">
        <WalletBtn ref="WalletBtn" :account="ethAccount" @change="init(true)"></WalletBtn>
      </div>
      <div>
        <van-cell-group>
          
          <van-cell :title="$t('user.SN')" :label="userData.sn"></van-cell>
          <van-cell :title="$t('user.DeeperChainWallet')" :label="userData.deeperChain"></van-cell>
          <van-cell :title="$t('user.ERC20')">
            <div slot="label">
              <div>{{userData.erc20}}</div>
              <div style="color: orangered" v-if="ethAccount && userData.erc20.toLowerCase() != ethAccount.toLowerCase()">
                {{$t('user.needRegisterWallet')}}
              </div>
            </div>
          </van-cell>
          <van-cell :title="$t('user.DPRBalance')" >
            <div slot="default">
              {{dprBalance}} <span style="color: orangered" v-if="dprBalance < stakeAmount">({{$t('user.noBalance')}})</span>
            </div>
          </van-cell>
        </van-cell-group>
        <div class="amountBox">
          <div style="font-size: 20px; font-weight: bold">
            {{stakeAmount}}
          </div>
          <div style="margin-left: 30px;">
            <img :style="`width: 30px`" :src="require('@/assets/dprCoin.png')" alt="">
          </div>
        </div>
        <div class="btnBox">
          <van-button :loading="stakeLoading" :disabled="stakingDisable()" @click="staking" style="width: 50%" round type="info">{{$t('user.stake')}}</van-button>
          <div>
            <van-button style="width: 50%;margin-top: 30px" size="small" round @click="$emit('prev')">{{$t('user.previous')}}</van-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h3 style="font-size: 14px;text-align: center">{{$t('user.records')}}</h3>
      <table style="width: 100%; text-align: center;font-size: 12px">
        <thead>
          <tr>
            <th>{{$t('user.time')}}</th>
            <th>hash</th>
            <th>status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in txList" :key="item.hash">
            <td>{{$moment(item.time).format('YYYY-MM-DD HH:mm:ss')}}</td>
            <td>{{formatHash(item.hash)}}</td>
            <td style="display: flex; justify-content: space-around">
              <van-tag :type="getTxItemColor(item)">{{getTxItemStatus(item)}}</van-tag>
            </td>
            <td>
              <a target="_blank" style="font-size: 12px" :href="`https://etherscan.io/tx/${item.hash}`">{{$t('user.detail')}}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
  </div>
</template>

<script>
import Vue from 'vue'
import WalletBtn from "@/components/WalletBtn/WalletBtn";
import ethService from "@/services/eth.js";
export default {
  props: {
    comps: null
  },
  components: {
    WalletBtn
  },
  data() {
    return {
      ethAccount: '',
      stakeAmount: '',
      haveStakingAmount: '',
      haveStaking: null,
      haveStakingDeeperChain: '',
      stakeLoading: false,
      dprBalance: 'loading...',
      userData: {
        sn: '',
        erc20: '',
        deeperChain: ''
      },
      lang: {
        cn: {

        },
        en: {

        }
      },
      txList: [],
    };
  },
  methods: {
    formatHash(hash) {
      return hash.slice(0, 6) + '...' + hash.slice(-6)
    },
    getTxItemColor(item) {
      if (item.status === '') {
        return 'warning'
      }
      return item.status ? 'success' : 'danger'
    },
    getTxItemStatus(item) {
      if (item.status === '') {
        return 'Pending'
      }
        return item.status ? 'Success' : 'Fail'
    },
    async getTxList() {
      if (window.localStorage.getItem('tx')) {
        this.txList = JSON.parse(window.localStorage.getItem('tx')).reverse()
        for (let i = 0; i < this.txList.length; i++) {
          let item = this.txList[i];
          if (item.status !== '') {
            continue;
          }
          let status =  await ethService.deviceStake.getTransactionsStatus(item.hash)
          if (!status) {
            continue;
          }
          ethService.deviceStake.updateTxStatus(item.hash, status.status);
          console.log(status)
          this.txList[i].status = status.status
        }
      }
    },
    stakingDisable() {
      if (this.userData.erc20.toLowerCase() != this.ethAccount.toLowerCase()) {
        return true
      }
      if (this.dprBalance < this.stakeAmount) {
        return true
      }
      return false
    },
    async init(fromWalletChange) {
      console.log("init");
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!fromWalletChange) {
        await ethService
        .init()
        .catch((e) => {
          console.log(e);
        })
      }
      await this.ethInit()
      this.$toast.clear();
      if (!this.ethAccount) {
        return
      }
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.getStakeData()
      await this.getStaking()
      await this.getDPRBalance()
      this.getTxList()
      this.$toast.clear();
    },
    async getDPRBalance() {
      let dprBalance = await ethService.getBalance(this.ethAccount);
      this.dprBalance = (dprBalance/1e18).toFixed(4)/1
    },
    getStakeData() {
      const {userData, deeperChain} = this.comps.Step1;
      const dprAmount = this.comps.Step2.getDPRAmount();
      this.userData = {
        sn: userData.sn,
        deeperChain,
        erc20: userData.walletAddress
      }
      this.stakeAmount = dprAmount
      console.log(dprAmount)
    },
    async ethInit() {
      const accounts = await ethService.getAccount();
      this.ethAccount = accounts[0]
      console.log(accounts)
    },
    async getStaking() {
      this.getStakeData()
      if (this.haveStaking) {
        return this.haveStaking
      }
      let staking = await this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem('token')
        },
        url: '/user/stakingData'
      }).then(res => res.data)
      if (!staking) {
        return
      }
      this.haveStaking = staking;
      this.haveStakingAmount = staking.dprAmount;
      this.haveStakingDeeperChain = staking.deeperChain
      return staking
    },
    async staking() {
      if (this.txList.find(it => it.status === '')) {
        let confirmRes = await this.$dialog.confirm({
          title: this.$t('user.confirmStakeWithPending'),
        }).then(() => {
          return true
        }).catch(() => {
          return false
        })
        if (!confirmRes) {
          return
        }
      }
      this.stakeLoading = true
      console.log(this.userData)
      try {
        if (this.haveStakingAmount/1>0) {
          await ethService.deviceStake.addStake(this.userData.erc20, this.stakeAmount)
        } else {
          await ethService.deviceStake.stake(this.userData.erc20, this.userData.deeperChain, this.stakeAmount)
        }
        this.$emit('next')
        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } catch (e) {
        console.log(Object.keys(e))
        
        this.$Dialog({
          message: (e.receipt && e.receipt.transactionHash) ? ('ERROR Transaction Hash: ' + e.receipt.transactionHash) : e.message
        })
      }
      this.stakeLoading = false
    }
  }

};
</script>

<style lang="less" scoped>
.mainContainer {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 10px 20px;
  .amountTitle {
    text-align: center;
    padding-top: 30px;
  }
  .amountBox {
    display: flex;
    justify-content: center;
    align-items:  center;
    height: 80px;
    /deep/ .van-stepper__input {
      font-size: 20px
    }
  }
  .btnBox {
    text-align: center;
  }
}
.footerBtnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
</style>