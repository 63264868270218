import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";

const DPR = require('@/coins/DPR/DPR.js')
const DeviceStake = require('@/coins/DeviceStake/DeviceStake')

let dprContract;
let dprTestContract

window.BN = Web3.utils.BN;
const BN = window.BN;

let web3;

async function init(config = {}) {
  if (window.ethereum) {
    await window.ethereum.send('eth_requestAccounts');
    window.ethereum.on('accountsChanged', function (_accounts) {
      if (config.onAccountsChanged) {
        return config.onAccountsChanged(_accounts)
      }
      window.location.reload()
    });
  
    window.ethereum.on('chainChanged', (chainId) => {
      window.location.reload()
    });
    web3 = new Web3(window.ethereum);
  } else {
    let providerList = [
      'https://mainnet.infura.io/v3/bd22e70259d546dd832b63b7cab12ed0',
      'https://mainnet.infura.io/v3/41f7a3c42f574496aaafdf1760dcf4a1',
      'https://mainnet.infura.io/v3/8984f83aab174c348485fbb803efb813'
    ]
    web3 = new Web3(providerList[Math.floor((Math.random()*providerList.length))])
  }
  
  dprContract = new web3.eth.Contract(DPR.abi, DPR.address)
  dprTestContract = new web3.eth.Contract(DPR.abi, DPR.address_test)
  return false;
}

function numToBN(number, level = 18) {
  return new BN(number+'').mul(new BN('10').pow(new BN(level+''))).toString()
}

let txList = []
if (window.localStorage.getItem("tx")) {
  txList = JSON.parse(window.localStorage.getItem("tx"));
}
function setTx(hash) {
  txList.push({time: Date.now(), hash, status: ''})
  window.localStorage.setItem('tx', JSON.stringify(txList))
}


const ethService = {
  init,
  async walletConnect() {
    window.localStorage.removeItem('walletconnect')
    const provider = new WalletConnectProvider({
      infuraId: "bd22e70259d546dd832b63b7cab12ed0",
    });
    await provider.enable();
    web3.setProvider(provider)
    return
  },
  async metamask() {
    if (typeof window.ethereum === 'undefined') {
      alert('MetaMask is not installed!');
      return
    }
    ethereum.request({ method: 'eth_requestAccounts' });
    web3.setProvider(Web3.givenProvider)
    return
  },
  getAccount() {
    return web3.eth.getAccounts();
  },
  getChainId() {
    return web3.eth.getChainId()
  },
  async getBalance(account) {
    let chainId = await web3.eth.getChainId()
    console.log(chainId)
    if (chainId == '0x1') {
      return dprContract.methods.balanceOf(account).call()
    }
    return dprTestContract.methods.balanceOf(account).call()
  },
  getSearchWeb3() {
    let providerList = [
      'https://mainnet.infura.io/v3/bd22e70259d546dd832b63b7cab12ed0',
      'https://mainnet.infura.io/v3/41f7a3c42f574496aaafdf1760dcf4a1',
      'https://mainnet.infura.io/v3/8984f83aab174c348485fbb803efb813'
    ]
    return new Web3(providerList[Math.floor((Math.random()*providerList.length))])
  },
  async getETHBalance(address) {
    return web3.eth.getBalance(address)
  },
  async approve(address, amount, from) {
    let chainId = await web3.eth.getChainId()
    const dprC = chainId == '0x1' ? dprContract : dprTestContract
    const allowance = await dprC.methods.allowance(from, address).call();
    console.log(allowance)
    if (new BN(amount).lte(new BN(allowance))) {
      return;
    }
    let alertMessage = window.navigator.language == 'zh-CN' ? '您需要支付两次，第一次支付是授权，第二次是质押。' : 'Your wallet needs to be authorized first.You need to pay twice, the first time is authorization, the second time is staking.';
    alert(alertMessage)
    let maxNum = Array.from({length: 40}).reduce((preVal, curVal) => {
      return preVal + 'F'
    }, '0X')
    return dprC.methods.approve(address, maxNum).send({ from });
  },
  tools: {
    numToBN
  },
  deviceStake: {
    getStaking(account) {
      if (!web3.currentProvider) {
        throw 'Please connect your wallet.'
      }
      const deviceStakeContract = new web3.eth.Contract(DeviceStake.abi, DeviceStake[web3.currentProvider.chainId/1].address);
      return deviceStakeContract.methods.getStaking(account).call()
    },
    getAddressByDPRAddress(dprAddress) {
      if (!web3.currentProvider) {
        throw 'Please connect your wallet.'
      }
      const deviceStakeContract = new web3.eth.Contract(DeviceStake.abi, DeviceStake[web3.currentProvider.chainId/1].address);
      return deviceStakeContract.methods.getUserAddressByDPRAddress(dprAddress).call()
    },
    getUserDPRAddress(account) {
      if (!web3.currentProvider) {
        throw 'Please connect your wallet.'
      }
      const deviceStakeContract = new web3.eth.Contract(DeviceStake.abi, DeviceStake[web3.currentProvider.chainId/1].address);
      return deviceStakeContract.methods.getUserDPRAddress(account).call()
    },
    getUserStakingTime(account) {
      if (!web3.currentProvider) {
        throw 'Please connect your wallet.'
      }
      const deviceStakeContract = new web3.eth.Contract(DeviceStake.abi, DeviceStake[web3.currentProvider.chainId/1].address);
      return deviceStakeContract.methods.getUserStakingTime(account).call()
    },
    async stake(account, dprAddress, _amount) {
      if (!web3.currentProvider) {
        throw 'Please connect your wallet.'
      }
      if (dprAddress.length < 35) {
        throw 'wrong deeper chain'
      }
      const deviceStakeContract = new web3.eth.Contract(DeviceStake.abi, DeviceStake[web3.currentProvider.chainId/1].address);
      let amount = numToBN(_amount)
      const approve = await ethService.approve(DeviceStake[web3.currentProvider.chainId/1].address,amount, account)
      return deviceStakeContract.methods.stake(dprAddress, amount).send({
        from: account
      }).on("transactionHash", tx => {
        setTx(tx)
        console.log(tx)
      })
    },
    async dealerStake(account, dprAddress, _amount) {
      if (!web3.currentProvider) {
        throw 'Please connect your wallet.'
      }
      const deviceStakeContract = new web3.eth.Contract(DeviceStake.abi, DeviceStake[web3.currentProvider.chainId/1].address);
      let amount = numToBN(_amount)
      const approve = await ethService.approve(DeviceStake[web3.currentProvider.chainId/1].address,amount, account)
      return deviceStakeContract.methods.stake(dprAddress, amount).send({
        from: account
      }).on("transactionHash", tx => {
        setTx(tx)
        console.log(tx)
      })
    },
    async addStake(account, _amount) {
      if (!web3.currentProvider) {
        throw 'Please connect your wallet.'
      }
      console.log('addStake')
      const deviceStakeContract = new web3.eth.Contract(DeviceStake.abi, DeviceStake[web3.currentProvider.chainId/1].address);
      let amount = numToBN(_amount)
      const approve = await ethService.approve(DeviceStake[web3.currentProvider.chainId/1].address,amount, account)
      return deviceStakeContract.methods.addAndExtendStaking(amount).send({
        from: account
      }).on("transactionHash", tx => {
        setTx(tx)
        console.log(tx)
      })
    },
    async getTransactionsStatus(tx) {
      return web3.eth.getTransactionReceipt(tx)
    },
    updateTxStatus(tx, status) {
      for (let i = 0; i < txList.length; i++) {
        if (txList[i].hash == tx) {
          txList[i].status = status;
          window.localStorage.setItem('tx', JSON.stringify(txList))
          return
        }
      }
    }
  }
  
}

export default ethService