const scoreType1 = {
  '$100': {
    credit: 100,
    dpr: 5000,
    need: 5000,
    apr: 0.2,
  },
  '$200': {
    credit: 200,
    dpr: 10000,
    need: 10000,
    apr: 0.3,
  },
  '$300': {
    credit: 300,
    dpr: 20000,
    need: 20000,
    apr: 0.35,
  },
  '$400': {
    credit: 400,
    dpr: 30000,
    need: 30000,
    apr: 0.4,
  },
  '$500': {
    credit: 500,
    dpr: 50000,
    need: 50000,
    apr: 0.45,
  },
  '$600': {
    credit: 600,
    dpr: 60000,
    need: 60000,
    apr: 0.5,
  },
  '$700': {
    credit: 700,
    dpr: 80000,
    need: 80000,
    apr: 0.55,
  },
  '$800': {
    credit: 800,
    dpr: 100000,
    need: 100000,
    apr: 0.6,
  },
}
const scoreType2 = {
  '$0': {
    credit: 0,
    dpr: 0,
    need: 1000,
    apr: 0,
  },
  '$100': {
    credit: 100,
    dpr: 5000,
    need: 6000,
    apr: 0.2,
  },
  '$200': {
    credit: 200,
    dpr: 10000,
    need: 11000,
    apr: 0.3,
  },
  '$300': {
    credit: 300,
    dpr: 20000,
    need: 21000,
    apr: 0.35,
  },
  '$400': {
    credit: 400,
    dpr: 30000,
    need: 31000,
    apr: 0.4,
  },
  '$500': {
    credit: 500,
    dpr: 50000,
    need: 51000,
    apr: 0.45,
  },
  '$600': {
    credit: 600,
    dpr: 60000,
    need: 61000,
    apr: 0.5,
  },
  '$700': {
    credit: 700,
    dpr: 80000,
    need: 81000,
    apr: 0.55,
  },
  '$800': {
    credit: 800,
    dpr: 100000,
    need: 101000,
    apr: 0.6,
  },
}

const scoreType3 = {
  '$400': {
    credit: 400,
    dpr: 2000,
    reward: 35.616,
    need: 2000
  }
}

const scoreType4 = {
  '$100': {
    credit: 100,
    dpr: 5000,
    need: 0,
    apr: 0.2,
  },
  '$200': {
    credit: 200,
    dpr: 10000,
    need: 5000,
    apr: 0.3,
  },
  '$300': {
    credit: 300,
    dpr: 20000,
    need: 15000,
    apr: 0.35,
  },
  '$400': {
    credit: 400,
    dpr: 30000,
    need: 25000,
    apr: 0.4,
  },
  '$500': {
    credit: 500,
    dpr: 50000,
    need: 45000,
    apr: 0.45,
  },
  '$600': {
    credit: 600,
    dpr: 60000,
    need: 55000,
    apr: 0.5,
  },
  '$700': {
    credit: 700,
    dpr: 80000,
    need: 75000,
    apr: 0.55,
  },
  '$800': {
    credit: 800,
    dpr: 100000,
    need: 95000,
    apr: 0.6,
  },
}
module.exports = {
  getScore(deviceType, isOpenMinerBefore) {
    console.log('getScore')
    console.log(deviceType, isOpenMinerBefore)
    if (!deviceType) {
      return null
    }
    if (deviceType == 'DPR') {
      return scoreType4
    }
    if (deviceType == 'DPRB') {
      return scoreType3
    }
    if (deviceType == 'IGG' || deviceType == "HW" || deviceType == "HWA") {
      if (!isOpenMinerBefore) {
        return scoreType2
      }
      return scoreType1
    }
  }
}